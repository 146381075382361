.default-inner-container-with-breadcrumb {
  padding: 1rem 5rem 0 5rem !important;
}

@media (min-width: 1024px) {
  .default-inner-container-with-breadcrumb {
    padding: 1rem 5rem 0 5rem !important;
  }
}

@media (max-width: 960px) {
  .default-inner-container-with-breadcrumb {
    padding: 0.3rem 2.5rem 0 2.5rem !important;
  }
}

@media (max-width: 480px) {
  .default-inner-container-with-breadcrumb {
    padding: 0 1rem 0 0.6rem !important;
  }
}
